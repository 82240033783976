import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import Button from '@/components/atoms/Button';
import Grid from '@/components/atoms/Grid';
import Typography from '@/components/atoms/Typography';
import Container from '@/components/atoms/Container';
import Categories from '@/components/molecules/Categories';
import Link from '@/components/atoms/Link';

import mq from '@/styles/mq';

import { CategoryProps } from '@/components/molecules/Categories/Categories';

interface Props {
  title?: React.ReactNode;
  description?: React.ReactNode;
  link?: string;
  categories?: CategoryProps[];
  button?: string;
}

const ShopCategoriesRoot = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(0)};

  ${mq('lg')} {
    margin-top: ${({ theme }) => theme.spacing(10)};
  }
`;
const TextContainer = styled(Grid)``;
const CategoriesContainer = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(3)};

  ${mq('lg')} {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`;
const Title = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(5)};
  text-align: center;

  ${mq('lg')} {
    text-align: left;
    margin-top: 0;
  }
`;
const Content = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(1.5)};
  text-align: center;

  ${mq('lg')} {
    text-align: left;
  }
`;
const DesktopButton = styled(Button)`
  display: none;

  ${mq('lg')} {
    display: block;
    margin-top: ${({ theme }) => theme.spacing(2.5)};
  }
`;

const ShopCategories = ({
  title,
  description,
  link,
  categories,
  button,
}: Props): JSX.Element => {
  return (
    <Container maxWidth="lg">
      <ShopCategoriesRoot container>
        <TextContainer xxs={12} lg={4}>
          <Title variant="h2">{title}</Title>
          <Content color="textSecondary">{description}</Content>
          {/* <Link to={`${link}`}>
            <DesktopButton size="sm">{button}</DesktopButton>
          </Link> */}
        </TextContainer>
        <CategoriesContainer xxs={12} lg={8}>
          <Categories categories={categories} />
        </CategoriesContainer>
      </ShopCategoriesRoot>
    </Container>
  );
};

export const query = graphql`
  fragment ShopCategories on PrismicShopPageDataType {
    categories_cta
    categories_button
    categories_title {
      raw
    }
    categories_description {
      raw
    }
  }
`;

export default ShopCategories;
