import React, { HTMLAttributes } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalf } from '@fortawesome/pro-solid-svg-icons';
import styled from '@emotion/styled';

import Typography from '@/components/atoms/Typography';

export interface ReviewProps extends HTMLAttributes<HTMLDivElement> {
  /**
   How much stars to show
   */
  rating?: number | null;
  /**
   Text of the review
   */
  description?: React.ReactNode;
  /**
   Author of the review
   */
  author?: string | null;
}

const ReviewRoot = styled('div')`
  background-color: ${({ theme }) => theme.color.white.main};
  border-radius: 10px;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.04);
  padding: ${({ theme }) => theme.spacing(2.5)};
`;
const Star = styled(FontAwesomeIcon)`
  color: #fcd561;
  margin-right: 4px;
`;
const HalfStar = styled(FontAwesomeIcon)`
  color: #fcd561;
`;
const Description = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(1)};
  max-width: 280px;
`;
const Author = styled(Typography)`
  font-weight: 800;
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

/**
 <h3>Display a customer review</h3>
 **/
const Review = ({
  rating = 1,
  description,
  author,
  ...rest
}: ReviewProps): JSX.Element => {
  const value = rating && rating <= 5 ? rating : 1;
  const parts = value?.toString()?.split('.');

  const round = Number.isNaN(parseInt(parts?.[0], 10))
    ? 1
    : parseInt(parts?.[0], 10);
  const decimal = parts?.[1];

  const RatingStars = [...Array(round)].map((_el, i) => (
    <Star key={i.toString()} icon={faStar} />
  ));

  if (decimal) {
    RatingStars?.push(<HalfStar key="decimal" icon={faStarHalf} />);
  }

  return (
    <ReviewRoot {...rest}>
      <div>{RatingStars}</div>
      <Description variant="textSm">{description}</Description>
      <Author variant="textMd">{author}</Author>
    </ReviewRoot>
  );
};

export default Review;
