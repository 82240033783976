import React from 'react';
import { RichText } from 'prismic-reactjs';

import ShopHeader from '@/containers/Shop/ShopHeader';
import ShopCategories from '@/containers/Shop/ShopCategories';
import ShopPartners from '@/containers/Shop/ShopPartners';
import ShopInspire from '@/containers/Shop/ShopInspire';
import ShopReviews from '@/containers/Shop/ShopReviews';
import ShopContactBox from '@/containers/Shop/ShopContactBox';
import ShopStats from '@/containers/Shop/ShopStats';
import ShopEnvironnement from '@/containers/Shop/ShopEnvironnement';
import ShopMember from '@/containers/Shop/ShopMember';

import {
  PrismicCategoriesCategoriesGroupType,
  PrismicCompanyStatsStatsGroupType,
  PrismicPartnersPartnersGroupType,
  ShopPageQuery,
} from '../../../graphql-types';

interface Props {
  data: ShopPageQuery;
  type: string;
}

const ShopContainer = ({ data, type }: Props): JSX.Element => {
  // DATA

  const {
    hero_background,
    shop_title,
    shop_description,
    cta_label,
    cta_link,
    categories_title,
    categories_description,
    categories_cta,
    categories_button,
    env_title,
    env_content,
    env_button,
    env_button_link,
    env_background,
    member_title,
    member_content,
    member_button,
    member_button_link,
    member_background,
    partners_title,
    partners_mobile_title,
    inspire_title,
    inspirations,
    reviews_title,
    reviews_background,
    reviews,
    inspires,
  } = data?.prismicShopPage?.data || {};

  const { categories } = data?.prismicCategories?.data || {};

  const { partners } = data?.prismicPartners?.data || {};

  const { primary } = data?.prismicShopPageBody1Contactbox || {};

  const contactBoxCategories =
    data?.prismicShopPageBody2Contactbox?.primary || {};

  const companyStats = data?.prismicShopPageBodyCompanystats || {};

  // const {
  //   contact_box_background,
  //   contact_box_section_title,
  //   contact_box_content,
  // } = data?.prismicContactBox?.data || {};

  // const { stats } = data?.prismicCompanyStats?.data || {};

  // PROPS
  const description =
    shop_description?.text?.length > 250
      ? {
          first: shop_description?.text?.slice(0, 150),
          last: shop_description?.text?.slice(
            150,
            shop_description?.text?.length,
          ),
        }
      : { first: shop_description?.text, last: '' };

  const HeroProps = {
    title: <RichText render={shop_title?.raw} />,
    description,
    cta: {
      label: cta_label,
      link: cta_link?.url,
    },
    background: {
      url: hero_background?.url,
      alt: hero_background?.alt,
    },
  };

  const CategoriesProps = {
    button: categories_button,
    link: categories_cta,
    title: <RichText render={categories_title?.raw} />,
    description: <RichText render={categories_description?.raw} />,
    categories: categories?.map(
      (el: PrismicCategoriesCategoriesGroupType | null) => {
        const { image, title, category } = el || {};
        return {
          image: {
            url: image?.url,
            alt: image?.alt,
          },
          name: <RichText render={title?.raw} />,
          categoryId: category?.document !== null ? category?.document?.id : '',
          categoryUid:
            category?.document !== null ? category?.document?.uid : '',
        };
      },
    ),
  };

  const EnvironnementProps = {
    title: <RichText render={env_title?.raw} />,
    description: <RichText render={env_content?.raw} />,
    cta: {
      label: env_button,
      path: env_button_link,
    },
    background: {
      url: env_background?.url,
      alt: env_background?.alt,
    },
  };

  const MemberProps = {
    title: <RichText render={member_title?.raw} />,
    description: <RichText render={member_content?.raw} />,
    cta: {
      label: member_button,
      path: member_button_link,
    },
    background: {
      url: member_background?.url,
      alt: member_background?.alt,
    },
  };

  const PartnersProps = {
    title: <RichText render={partners_title?.raw} />,
    mobileTitle: <RichText render={partners_mobile_title?.raw} />,
    partners: partners?.map((el: PrismicPartnersPartnersGroupType | null) => ({
      name: el?.name,
      link: el?.link?.url,
      image: {
        url: el?.image?.url,
        alt: el?.image?.alt,
      },
    })),
  };

  // const InspireProps = {
  //   title: <RichText render={inspire_title?.raw} />,
  //   items: inspirations?.map((inspiration: any) => {
  //     const { image, title, link } = inspiration || {};

  //     return {
  //       background: {
  //         url: image?.url,
  //         alt: image?.alt,
  //       },
  //       title: <RichText render={title?.raw} />,
  //       link: link?.url,
  //     };
  //   }),
  // };

  // const InspireProps = {
  //   title: <RichText render={inspire_title?.raw} />,
  //   items: inspires?.map((inspire: any) => {
  //     //const { image, title, link } = inspire || {};
  //     const inspireData = inspire?.inspire?.document;

  //     return {
  //       link: `/magasin/actualites/${inspireData?.uid}`,
  //       background: {
  //         url: inspireData?.data?.hero_background?.url,
  //         alt: inspireData?.data?.hero_background?.alt,
  //       },
  //       title: <RichText render={inspireData?.data?.title?.raw} />,
  //     };
  //   }),
  // };

  const ReviewsProps = {
    title: <RichText render={reviews_title?.raw} />,
    image: {
      url: reviews_background?.url,
      alt: reviews_background?.alt,
    },
    reviews: reviews?.map((el: any | null) => {
      const { description, author, rating } = el?.review?.document?.data || {};

      return {
        rating,
        description: <RichText render={description?.raw} />,
        author,
      };
    }),
  };

  // const ContactBoxProps = {
  //   background: {
  //     url: contact_box_background?.url,
  //     alt: contact_box_background?.alt,
  //   },
  //   title: <RichText render={contact_box_section_title?.raw} />,
  //   content: <RichText render={contact_box_content?.raw} />,
  // };

  const ContactBoxProps = {
    title: <RichText render={primary?.title?.raw} />,
    content: <RichText render={primary?.content?.raw} />,
    button: {
      text: <RichText render={primary?.button_name?.raw} />,
      link: primary?.link,
    },
    background: {
      url: primary?.image?.url,
      alt: primary?.image?.alt,
    },
  };

  const ContactBoxCategoriesProps = {
    title: <RichText render={contactBoxCategories?.title?.raw} />,
    content: <RichText render={contactBoxCategories?.content?.raw} />,
    button: {
      text: <RichText render={contactBoxCategories?.button_name?.raw} />,
      link: contactBoxCategories?.link,
    },
    background: {
      url: contactBoxCategories?.image?.url,
      alt: contactBoxCategories?.image?.alt,
    },
  };

  const StatsProps = {
    stats: companyStats?.items?.map((item: any) => ({
      number: <RichText render={item?.number?.raw} />,
      label: item?.label?.text,
    })),
  };

  return (
    <>
      <ShopHeader {...HeroProps} />
      <ShopCategories {...CategoriesProps} />
      {contactBoxCategories && (
        <ShopContactBox {...ContactBoxCategoriesProps} />
      )}
      <ShopEnvironnement {...EnvironnementProps} />
      <ShopMember {...MemberProps} />
      <ShopReviews {...ReviewsProps} />
      <ShopPartners {...PartnersProps} />
      {/* <ShopInspire {...InspireProps} /> */}
      {primary && <ShopContactBox {...ContactBoxProps} />}
      {companyStats?.items?.length > 0 && <ShopStats {...StatsProps} />}
    </>
  );
};

export default ShopContainer;
