import React, { HTMLAttributes } from 'react';
import { graphql } from 'gatsby';
import AliceCarousel from 'react-alice-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import styled from '@emotion/styled';

import Button from '@/components/atoms/Button';
import mq from '@/styles/mq';
import Img from '@/components/atoms/Img';
import Typography from '@/components/atoms/Typography';

import ImageDataType from '@/types/data/image';
import Link from '@/components/atoms/Link';
import Grid from '@/components/atoms/Grid';

export interface CategoryProps extends HTMLAttributes<HTMLDivElement> {
  image?: ImageDataType;
  name?: React.ReactNode;
  categoryId?: string;
  categoryUid?: string;
}

interface Props {
  categories?: CategoryProps[];
}

const MobileBtnContainer = styled(Grid)`
  display: flex;

  ${mq('lg')} {
    display: none;
  }
`;
const MobileButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing(3)};
`;
const CarouselContainer = styled('div')`
  display: block;

  ${mq('lg')} {
    display: none;
  }
`;
const Prev = styled(Button)`
  position: absolute;
  left: 0;
  top: 100px;
`;
const Next = styled(Button)`
  position: absolute;
  right: 0;
  top: 100px;
`;
const CategoryRoot = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const CategoryLink = styled(Link)`
  display: block;
`;
const Image = styled(Img)`
  border-radius: 10px;
  width: 150px;
  height: 150px;
`;
const Name = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(1.5)};
  font-weight: 800;
`;
const DesktopGrid = styled('div')`
  display: none;

  ${mq('lg')} {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    ${CategoryLink} {
      flex: 33%;
      margin-bottom: ${({ theme }) => theme.spacing(5)};
    }
  }
`;

const Category = ({
  categoryId,
  categoryUid,
  image,
  name,
}: CategoryProps): JSX.Element => {
  const { url, alt } = image || {};
  const setCategory = (id: string) => {
    localStorage.setItem('categoryId', id);
  };
  return (
    <CategoryLink
      to="/magasin/produits"
      onClick={() => setCategory(categoryId as string)}
    >
      <CategoryRoot>
        {url && <Image src={url} alt={alt || ''} sizes="200px" />}
        <Name variant="textLg">{name}</Name>
      </CategoryRoot>
      <MobileBtnContainer container justifyContent="center">
        <MobileButton as={Link} {...{ to: `/magasin/produits` }}>
          En savoir plus
        </MobileButton>
      </MobileBtnContainer>
    </CategoryLink>
  );
};

const Categories = ({ categories }: Props): JSX.Element => {
  const handleDragStart = (e: React.MouseEvent) => e.preventDefault();

  const CategoriesElements = categories?.map((category, index) => (
    <Category
      onDragStart={handleDragStart}
      key={index.toString()}
      {...category}
    />
  ));

  return (
    <>
      <CarouselContainer>
        <AliceCarousel
          mouseTracking
          items={CategoriesElements}
          infinite
          disableDotsControls
          renderPrevButton={(): JSX.Element => (
            <Prev variant="text">
              <FontAwesomeIcon icon={faArrowLeft} />
            </Prev>
          )}
          renderNextButton={(): JSX.Element => (
            <Next variant="text">
              <FontAwesomeIcon icon={faArrowRight} />
            </Next>
          )}
        />
      </CarouselContainer>
      <DesktopGrid>{CategoriesElements}</DesktopGrid>
    </>
  );
};

export const query = graphql`
  fragment Categories on PrismicCategoriesDataType {
    categories {
      title {
        raw
      }
      image {
        url
        alt
      }
      category {
        document {
          ... on PrismicPrimaryCategory {
            id
            uid
          }
        }
      }
    }
  }
`;

export default Categories;
