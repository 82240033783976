import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@/components/organisms/Layout';
import Seo from '@/components/atoms/Seo';
import ShopContainer from '@/containers/Shop/ShopContainer';

import { ShopPageQuery } from '../../../graphql-types';

interface Props {
  data: ShopPageQuery;
}

export const pageQuery = graphql`
  query ShopPage {
    prismicShopPage {
      data {
        meta_title
        meta_description
        ...ShopHero
        ...ShopCategories
        ...ShopEnvironnement
        ...ShopMember
        ...ShopPartners
        ...ShopInspire
        ...ShopReviews
      }
    }
    prismicShopPageBodyCompanystats {
      primary {
        type
      }
      items {
        number {
          raw
        }
        label {
          text
        }
      }
    }
    prismicShopPageBody1Contactbox {
      primary {
        title {
          raw
        }
        content {
          raw
        }
        button_name {
          raw
        }
        link
        image {
          url
          alt
        }
      }
    }
    prismicShopPageBody2Contactbox {
      primary {
        title {
          raw
        }
        content {
          raw
        }
        button_name {
          raw
        }
        link
        image {
          url
          alt
        }
      }
    }
    prismicCategories {
      data {
        ...Categories
      }
    }
    prismicPartners {
      data {
        ...Partners
      }
    }
    prismicCompanyStats {
      data {
        ...CompanyStats
      }
    }
  }
`;

const Shop = ({ data }: Props): JSX.Element => {
  const { meta_title, meta_description } = data?.prismicShopPage?.data || {};

  return (
    <Layout invertNav type="magasin">
      <Seo
        title={meta_title || 'title'}
        description={meta_description || 'description'}
      />
      <ShopContainer data={data} type="magasin" />
    </Layout>
  );
};

export default Shop;
