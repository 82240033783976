import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import AliceCarousel from 'react-alice-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';

import Grid from '@/components/atoms/Grid';
import Typography from '@/components/atoms/Typography';
import Img from '@/components/atoms/Img';
import Review from '@/components/atoms/Review';
import Container from '@/components/atoms/Container';
import Button from '@/components/atoms/Button';

import mq from '@/styles/mq';

import ImageDataType from '@/types/data/image';
import { ReviewProps } from '@/components/atoms/Review/Review';

interface Props {
  title?: React.ReactNode;
  image?: ImageDataType;
  reviews?: ReviewProps[];
}

const ShopReviewsRoot = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(10)};
  background-color: ${({ theme }) => theme.color.secondary.main};

  ${mq('lg')} {
    margin-top: ${({ theme }) => theme.spacing(12)};
  }
`;
const SectionContainer = styled(Container)`
  padding-top: ${({ theme }) => theme.spacing(5)};
  padding-bottom: ${({ theme }) => theme.spacing(5)};

  ${mq('lg')} {
    padding-top: ${({ theme }) => theme.spacing(4)};
    padding-bottom: ${({ theme }) => theme.spacing(10)};
    padding-left: ${({ theme }) => theme.spacing(4)};
    padding-right: 0;
  }
`;
const Title = styled(Typography)`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;
const ImageGrid = styled(Grid)`
  display: none;

  ${mq('lg')} {
    display: block;
    height: 510px;
    overflow: hidden;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
`;
const Image = styled(Img)`
  object-position: top center;
`;
const ReviewStyled = styled(Review)`
  max-width: 350px;
  width: 100%;
  margin: auto;

  ${mq('lg')} {
    margin: initial;
  }
`;
const ReviewsGrid = styled(Grid)`
  display: none;

  ${mq('lg')} {
    display: block;
    position: relative;
    width: 100%;
    max-width: ${({ theme }) => theme.breakpoints.lg / 2}px;
    margin-left: auto;
    height: 510px;

    ${ReviewStyled}:first-of-type {
      position: absolute;
      left: 0;
      top: 50px;
    }

    ${ReviewStyled}:nth-of-type(2) {
      position: absolute;
      top: 100px;
      right: -250px;
    }

    ${ReviewStyled}:last-of-type {
      position: absolute;
      top: 300px;
      left: 100px;
    }
  }
`;
const ReviewsCarousel = styled('div')`
  display: block;
  width: 100%;
  .ew2effb0 {
    position: absolute;
    right: -14px;
    top: center;
  }
  .ew2effb1 {
    position: absolute;
    left: -14px;
    top: center;
  }
  ${mq('lg')} {
    display: none;
  }
`;
const Prev = styled(Button)`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;
const Next = styled(Button)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const ShopReviews = ({ title, image, reviews }: Props): JSX.Element => {
  const { url, alt } = image || {};

  const handleDragStart = (e: React.MouseEvent) => e.preventDefault();

  const Reviews = reviews?.map((review, index) => (
    <ReviewStyled onDrag={handleDragStart} key={index.toString()} {...review} />
  ));

  return (
    <ShopReviewsRoot>
      <SectionContainer maxWidth="xxl">
        <Title variant="h2">{title}</Title>
        <Grid container>
          <ReviewsGrid lg={6}>{Reviews}</ReviewsGrid>
          <ReviewsCarousel>
            <AliceCarousel
              mouseTracking
              infinite
              disableDotsControls
              responsive={{ 0: { items: 1 } }}
              renderPrevButton={(): JSX.Element => (
                <Prev variant="text">
                  <FontAwesomeIcon icon={faArrowLeft} />
                </Prev>
              )}
              renderNextButton={(): JSX.Element => (
                <Next variant="text">
                  <FontAwesomeIcon icon={faArrowRight} />
                </Next>
              )}
              items={Reviews}
            />
          </ReviewsCarousel>
          <ImageGrid lg={6}>
            {url && <Image src={url} alt={alt || ''} sizes="50vw" />}
          </ImageGrid>
        </Grid>
      </SectionContainer>
    </ShopReviewsRoot>
  );
};

export const query = graphql`
  fragment ShopReviews on PrismicShopPageDataType {
    reviews_title {
      raw
    }
    reviews_background {
      url
      alt
    }
    reviews {
      review {
        document {
          ... on PrismicReview {
            ...Review
          }
        }
      }
    }
  }
`;

export default ShopReviews;
