import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import Button from '@/components/atoms/Button';
import Link from '@/components/atoms/Link';
import Background from '@/components/atoms/Background';
import Container from '@/components/atoms/Container';
import Typography from '@/components/atoms/Typography';
import Grid from '@/components/atoms/Grid';

import mq from '@/styles/mq';

import ImageDataType from '@/types/data/image';
import LinkDataType from '@/types/data/link';

interface Props {
  title?: React.ReactNode;
  description?: React.ReactNode;
  cta?: LinkDataType;
  background?: ImageDataType;
}

const ShopHeaderRoot = styled('div')`
  background-image: linear-gradient(
    180deg,
    ${({ theme }) => theme.color.secondary.main} 200%,
    #fff 200%
  );
  padding-bottom: ${({ theme }) => theme.spacing(5)};
`;
const ShopHeaderContainer = styled(Container)`
  padding-left: 0;
  padding-right: 0;

  ${mq('lg')} {
    padding-top: ${({ theme }) => theme.spacing(15)};
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
  }
`;
const LeftSideGrid = styled(Grid)`
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  order: 2;

  ${mq('lg')} {
    padding-left: 0;
    padding-right: ${({ theme }) => theme.spacing(3.5)};
    order: 1;
  }
`;
const RightSideGrid = styled(Grid)`
  order: 1;

  ${mq('lg')} {
    order: 2;
  }
`;
const Title = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(5)};

  ${mq('lg')} {
    margin-top: 0;
  }
`;
const Description = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(3)};
  font-weight: 500;
`;
const ReadMoreLess = styled(Typography)`
  text-decoration: underline;
  &:hover {
    color: ${({ theme }) => theme.color.black.main};
    cursor: pointer;
  }
`;
const Cta = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing(3)};
`;
const HeroBg = styled(Background)`
  width: 100%;
  height: 360px;

  ${mq('md')} {
    /* width: 550px; */
    /* height: 750px; */
    width: 100%;
    height: 400px;
  }

  ${mq('lg')} {
    border-radius: 10px;
  }
`;

const ShopHeader = ({
  title,
  description,
  cta,
  background,
}: Props): JSX.Element => {
  const [readMore, setReadMore] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(0);
  const { label, path } = cta || {};
  //@ts-ignore
  const { first, last } = description;

  useEffect(() => {
    const setDimensions = () => {
      const winWidth = typeof window !== 'undefined' && window.innerWidth;
      setWidth(winWidth as number);
    };
    setDimensions();
    window.addEventListener('resize', setDimensions);
    return () => window.removeEventListener('resize', setDimensions);
  });

  const renderDescription = () => {
    if (width < 576 && last !== '') {
      return (
        <Description id="IndustryDesc">
          {first} {!readMore && '...'}
          {!readMore && (
            <ReadMoreLess
              variant="textXs"
              color="textSecondary"
              onClick={() => setReadMore(true)}
            >
              Lire plus
            </ReadMoreLess>
          )}
          {readMore && last}
          {readMore && (
            <Link to="#IndustryDesc">
              <ReadMoreLess
                variant="textXs"
                color="textSecondary"
                onClick={() => setReadMore(false)}
              >
                Lire moins
              </ReadMoreLess>
            </Link>
          )}
        </Description>
      );
    }
    return (
      <Description>
        {first}
        {last}
      </Description>
    );
  };
  return (
    <ShopHeaderRoot>
      <ShopHeaderContainer maxWidth="lg">
        <Grid container>
          <LeftSideGrid xxs={12} lg={5}>
            <Title variant="h1">{title}</Title>
            {renderDescription()}
            {path && (
              <Cta as={Link} {...{ to: path }} size="sm">
                {label}
              </Cta>
            )}
          </LeftSideGrid>
          <RightSideGrid xxs={12} lg={7}>
            <HeroBg background={background} overlay={0} />
          </RightSideGrid>
        </Grid>
      </ShopHeaderContainer>
    </ShopHeaderRoot>
  );
};

export const query = graphql`
  fragment ShopHero on PrismicShopPageDataType {
    hero_background {
      url
      alt
    }
    shop_title {
      raw
    }
    shop_description {
      text
    }
    cta_label
    cta_link
  }
`;

export default ShopHeader;
