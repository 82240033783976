import React, { HTMLAttributes } from 'react';
import { graphql } from 'gatsby';
import AliceCarousel from 'react-alice-carousel';
import styled from '@emotion/styled';

import Typography from '@/components/atoms/Typography';
import Link from '@/components/atoms/Link';
import Img from '@/components/atoms/Img';

import mq from '@/styles/mq';

export interface PartnerProps extends HTMLAttributes<HTMLDivElement> {
  name?: string | null;
  link?: string;
  image?: {
    url: string;
    alt: string;
  };
}

interface Props {
  partners?: PartnerProps[];
}

const PartnerFlex = styled('div')`
  position: relative;
  /* height: 50px; */
  width: 100%;
  ${mq('lg')} {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;
const PartnerName = styled(Typography)`
  text-align: center;
  display: flex;
  justify-content: center;
  &:hover {
    color: ${({ theme }) => theme.color.textPrimary.main};
  }

  /* ${mq('lg')} {
    position: absolute;
    text-align: initial;
    max-width: 200px;
  } */
`;
const PartnerImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 70px;
  }
`;
const DesktopPartners = styled('div')`
  display: none;

  ${mq('lg')} {
    display: block;

    & ${PartnerFlex}:nth-of-type(5n + 1) ${PartnerName} {
      left: 0;
    }

    & ${PartnerFlex}:nth-of-type(5n + 2) ${PartnerName} {
      right: 0;
    }

    & ${PartnerFlex}:nth-of-type(5n + 3) ${PartnerName} {
      left: 100px;
    }

    & ${PartnerFlex}:nth-of-type(5n + 4) ${PartnerName} {
      right: -100px;
    }

    & ${PartnerFlex}:nth-of-type(5n + 5) ${PartnerName} {
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;
const MobilePartners = styled('div')`
  display: block;
  margin-top: ${({ theme }) => theme.spacing(3)};
  cursor: grab;
  /* ${mq('lg')} {
    display: none;
  } */
`;

const Partner = ({ image, name, link }: PartnerProps): JSX.Element => {
  return (
    <a href={link || ''} target="_blank" rel="noreferrer">
      <PartnerFlex>
        {/* <PartnerName variant="h3" color="textSecondary">
          {name}
        </PartnerName> */}
        <PartnerImage>
          {image?.url && <Img src={image?.url as string} />}
        </PartnerImage>
      </PartnerFlex>
    </a>
  );
};

const Partners = ({ partners }: Props): JSX.Element => {
  const handleDragStart = (e: React.MouseEvent) => e.preventDefault();
  const PartnersElements = partners?.map((partner, i) => (
    <Partner onDragStart={handleDragStart} key={i.toString()} {...partner} />
  ));

  return (
    <>
      {/* <DesktopPartners>{PartnersElements}</DesktopPartners> */}
      <MobilePartners>
        <AliceCarousel
          items={PartnersElements}
          autoPlay
          autoPlayInterval={1500}
          mouseTracking
          infinite
          disableButtonsControls
          disableDotsControls
          responsive={{ 0: { items: 2 }, 576: { items: 4 }, 768: { items: 6 } }}
        />
      </MobilePartners>
    </>
  );
};

export const query = graphql`
  fragment Partners on PrismicPartnersDataType {
    partners {
      name
      link {
        url
      }
      image {
        url
        alt
      }
    }
  }
`;

export default Partners;
