import React, { HTMLAttributes } from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import Container from '@/components/atoms/Container';
import Grid from '@/components/atoms/Grid';
import Typography from '@/components/atoms/Typography';
import Partners from '@/components/molecules/Partners';

import mq from '@/styles/mq';

import DoubleEllipse from '@/svg/double-ellipse.svg';

interface PartnerProps extends HTMLAttributes<HTMLDivElement> {
  name?: string | null;
}

interface Props {
  title?: React.ReactNode;
  mobileTitle?: React.ReactNode;
  partners?: PartnerProps[];
}

const ShopPartnersRoot = styled(Container)`
  margin-top: ${({ theme }) => theme.spacing(0)};
  margin-bottom: ${({ theme }) => theme.spacing(5)};
  ${mq('lg')} {
    margin-top: ${({ theme }) => theme.spacing(15)};
  }
`;
const TextGrid = styled(Grid)`
  position: relative;

  ${mq('lg')} {
    margin-top: ${({ theme }) => theme.spacing(10)};
  }
`;
const PartnersGrid = styled(Grid)``;

const DoubleEllipseBg = styled(DoubleEllipse)`
  /* display: none; */
  height: 200px;
  width: auto;
  position: absolute;
  left: 5%;
  top: 70%;
  ${mq('xxs')} {
    left: 10%;
    top: 50%;
  }
  ${mq('xs')} {
    left: 20%;
  }
  ${mq('md')} {
    left: 30%;
  }
  ${mq('lg')} {
    display: block;
    position: absolute;
    /* right: 0; */
    left: 30%;
    top: 50%;
    transform: translateY(-50%);
    z-index: ${({ theme }) => theme.zIndex.overlay};
    height: 450px;
    width: auto;
  }
`;
const Title = styled(Typography)`
  /* display: none; */
  text-align: center;
  position: relative;
  top: 100px;
  ${mq('lg')} {
    display: block;
    position: relative;
    top: 0;
    z-index: ${({ theme }) => theme.zIndex.content};
  }
`;
const MobileTitle = styled(Typography)`
  display: block;
  text-align: center;

  /* ${mq('lg')} {
    display: none;
  } */
`;

const ShopPartners = ({ title, mobileTitle, partners }: Props): JSX.Element => {
  return (
    <ShopPartnersRoot maxWidth="lg">
      <Grid container>
        {/* <TextGrid xxs={12} lg={6}>
          <DoubleEllipseBg />
          <Title variant="h2">{title}</Title>
          <MobileTitle variant="h2">{mobileTitle}</MobileTitle>
        </TextGrid> */}
        <TextGrid xxs={12} lg={12}>
          <DoubleEllipseBg />
          <Title variant="h2">{title}</Title>
          {/* <MobileTitle variant="h2">{mobileTitle}</MobileTitle> */}
        </TextGrid>
        {/* <PartnersGrid xxs={12} lg={4}>
          <Partners partners={partners} />
        </PartnersGrid> */}
        <PartnersGrid xxs={12} lg={15} mt={20}>
          <Partners partners={partners} />
        </PartnersGrid>
      </Grid>
    </ShopPartnersRoot>
  );
};

export const query = graphql`
  fragment ShopPartners on PrismicShopPageDataType {
    partners_title {
      raw
    }
    partners_mobile_title {
      raw
    }
  }
`;

export default ShopPartners;
